<template>
    <v-container>
        <v-row>
            <v-col cols="3" md="5" lg="3">
                <v-card
                    class="pa-5 profile"
                    height="calc(100vh - 210px)"
                    min-width="250"
                >
                    <v-card-title class="px-0">
                        {{ $t('profile:yourData') }}
                    </v-card-title>
                    <div class="profile__content">
                        <div class="profile__avatar">
                            <v-avatar size="160" color="primary">
                                <img
                                    v-if="getProfilePicture.url"
                                    :src="getProfilePicture.url"
                                    alt="avatar"
                                />

                                <span v-else class="white--text headline"
                                    >{{ getProfileDetails.name[0]
                                    }}{{ getProfileDetails.lastName[0] }}</span
                                >
                            </v-avatar>

                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-btn
                                        class="profile__edit"
                                        width="25"
                                        height="25"
                                        icon
                                        @click="open = true"
                                        v-on="on"
                                    >
                                        <v-icon size="16" color="white">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('profile:insertPhoto') }}</span>
                            </v-tooltip>
                        </div>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="title text-wrap center"
                                >
                                    {{ getProfileDetails.name }}
                                    {{ getProfileDetails.lastName }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="center">
                                    {{ getProfileDetails.position }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <div class="d-flex flex-column mb-5 pb-5">
                        <span style="font-size: 13px; font-weight: 700">{{
                            getProfileDetails.contact.name
                        }}</span>
                        <span style="font-size: 13px"
                            >ID:{{ getProfileDetails.contact.contactId }}</span
                        >
                    </div>
                    <div class="mt-5 pt-5">
                        <v-text-field
                            readonly
                            :label="$t('profile:login')"
                            :value="getProfileDetails.login"
                        />
                        <v-text-field
                            readonly
                            :label="$t('profile:password')"
                            value="************"
                            append-outer-icon="mdi-lock"
                        >
                            <template #append-outer>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="cursor-pointer"
                                            v-on="on"
                                            @click="
                                                $emit('open-profile'),
                                                    clearProfileModal()
                                            "
                                        >
                                            mdi-lock
                                        </v-icon>
                                    </template>
                                    <span>{{
                                        $t('global:changePassword')
                                    }}</span>
                                </v-tooltip>
                            </template></v-text-field
                        >
                        <v-text-field
                            readonly
                            :label="$t('profile:mobilePhone')"
                            :value="getProfileDetails.phoneNumber"
                            append-outer-icon="mdi-cog"
                        >
                            <template #append-outer>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="cursor-pointer"
                                            v-on="on"
                                            @click="
                                                $emit('open-profile-personal'),
                                                    clearProfileModal()
                                            "
                                        >
                                            mdi-cog
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('global:editPhone') }}</span>
                                </v-tooltip>
                            </template></v-text-field
                        >
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <Modal
            :title="$t('global:edit')"
            :open="open"
            width="700"
            @close="closeModal"
        >
            <AEContent slot="AEContent" />
            <Buttons slot="buttons" @close-modal="open = false" />
        </Modal>
    </v-container>
</template>
<script>
import AEContent from './Modal/Photo/ProfileModalPhotoPhoto'
import Buttons from './Modal/Photo/ProfileModalPhotoButtons'
import { mapGetters, mapMutations } from 'vuex'

export default {
    components: {
        Buttons,
        AEContent,
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapGetters(['getProfileDetails', 'getProfilePicture']),
    },
    methods: {
        ...mapMutations(['clearProfileModal']),
        closeModal() {
            // this.clearFotoModal()
            this.open = false
        },
    },
}
</script>
