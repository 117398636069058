<template>
    <v-container class="d-flex justify-space-between px-2 py-0">
        <div class="central__title d-flex align-center">
            <router-link tag="div" :to="`/${profileUrl}`">
                <h1 class="font-weight-light cursor-pointer mt-1">
                    {{ title }}
                </h1>
            </router-link>
        </div>

        <v-menu transition="slide-x-transition" bottom right>
            <template #activator="{ on }">
                <v-btn class="buttons--language" width="135px" text v-on="on">
                    <v-avatar size="30">
                        <img
                            :src="getProfileDetails.language.avatar"
                            alt="avatar"
                        />
                    </v-avatar>
                    <span class="ma-2 font-weight-light">{{
                        getProfileDetails.language.name
                    }}</span>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="item in languages"
                    :key="item.language"
                    class="cursor-pointer"
                    @click="
                        changeLanguage({
                            id: getProfileDetails._id,
                            language: item.language,
                        })
                    "
                >
                    <v-avatar class="ma-2" size="30">
                        <img :src="item.avatar" alt="avatar" />
                    </v-avatar>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-container>
</template>
<script>
import Rest from './../../../plugins/Rest'
import store from './../../../store/Personal/profile'
import { mapActions, mapGetters, mapMutations } from 'vuex'
/**
 * Profile Header
 * @displayName  Profile Header
 */
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        /**
         * Main view / Title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * profile Url
         */
        profileUrl: {
            type: String,
        },
        open: {
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    data() {
        return {
            languages: [
                {
                    name: this.$t('profile:english'),
                    language: 'en',
                    avatar: 'https://cdn-icons-png.flaticon.com/512/197/197374.png',
                },
                {
                    name: this.$t('profile:polish'),
                    language: 'pl',
                    avatar: 'https://cdn-icons-png.flaticon.com/512/197/197529.png',
                },
                // {
                //     name: this.$t('profile:spanish'),
                //     language: 'es',
                //     avatar:
                //         'https://cdn-icons-png.flaticon.com/512/197/197593.png',
                // },
                // {
                //     name: this.$t('profile:ukrainian'),
                //     language: 'ua',
                //     avatar:
                //         'https://image.flaticon.com/icons/svg/197/197572.svg',
                // },
            ],
        }
    },
    methods: {
        ...mapActions(['changeLanguage']),
        ...mapMutations(['setLanguage']),
        checkLanguage() {
            this.languages.forEach(lang => {
                if (lang.language == this.getProfileDetails.language) {
                    this.setLanguage(lang)
                }
            })
        },
    },
    watch: {
        'getProfileDetails.language': function (val) {
            this.checkLanguage()
        },
    },
    beforeMount() {
        this.checkLanguage()
    },
}
</script>
