<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="e1" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider />
                            <v-stepper-step
                                :rules="[
                                    () => getProfileError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                            >
                                {{ $t('profile:editProfile') }}
                            </v-stepper-step>
                            <v-divider />
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('profile:editProfile')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="12" md="12">
                                        <v-text-field
                                            :label="$t('profile:mobilePhone')"
                                            v-model="
                                                getProfile.phoneNumber
                                            " /></v-col
                                ></v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['profile'] },
    data() {
        return {
            e1: 1,
            editable: true,
        }
    },

    computed: {
        ...mapGetters(['getProfile', 'getProfileError']),
    },
    methods: {},
}
</script>
