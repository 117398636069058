var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"5","lg":"3"}},[_c('v-card',{staticClass:"pa-5 profile",attrs:{"height":"calc(100vh - 210px)","min-width":"250"}},[_c('v-card-title',{staticClass:"px-0"},[_vm._v(" "+_vm._s(_vm.$t('profile:yourData'))+" ")]),_c('div',{staticClass:"profile__content"},[_c('div',{staticClass:"profile__avatar"},[_c('v-avatar',{attrs:{"size":"160","color":"primary"}},[(_vm.getProfilePicture.url)?_c('img',{attrs:{"src":_vm.getProfilePicture.url,"alt":"avatar"}}):_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.getProfileDetails.name[0])+_vm._s(_vm.getProfileDetails.lastName[0]))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"profile__edit",attrs:{"width":"25","height":"25","icon":""},on:{"click":function($event){_vm.open = true}}},on),[_c('v-icon',{attrs:{"size":"16","color":"white"}},[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('profile:insertPhoto')))])])],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title text-wrap center"},[_vm._v(" "+_vm._s(_vm.getProfileDetails.name)+" "+_vm._s(_vm.getProfileDetails.lastName)+" ")]),_c('v-list-item-subtitle',{staticClass:"center"},[_vm._v(" "+_vm._s(_vm.getProfileDetails.position)+" ")])],1)],1)],1),_c('div',{staticClass:"d-flex flex-column mb-5 pb-5"},[_c('span',{staticStyle:{"font-size":"13px","font-weight":"700"}},[_vm._v(_vm._s(_vm.getProfileDetails.contact.name))]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("ID:"+_vm._s(_vm.getProfileDetails.contact.contactId))])]),_c('div',{staticClass:"mt-5 pt-5"},[_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('profile:login'),"value":_vm.getProfileDetails.login}}),_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('profile:password'),"value":"************","append-outer-icon":"mdi-lock"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"cursor-pointer",on:{"click":function($event){_vm.$emit('open-profile'),
                                                _vm.clearProfileModal()}}},on),[_vm._v(" mdi-lock ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('global:changePassword')))])])]},proxy:true}])}),_c('v-text-field',{attrs:{"readonly":"","label":_vm.$t('profile:mobilePhone'),"value":_vm.getProfileDetails.phoneNumber,"append-outer-icon":"mdi-cog"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"cursor-pointer",on:{"click":function($event){_vm.$emit('open-profile-personal'),
                                                _vm.clearProfileModal()}}},on),[_vm._v(" mdi-cog ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('global:editPhone')))])])]},proxy:true}])})],1)],1)],1)],1),_c('Modal',{attrs:{"title":_vm.$t('global:edit'),"open":_vm.open,"width":"700"},on:{"close":_vm.closeModal}},[_c('AEContent',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('Buttons',{attrs:{"slot":"buttons"},on:{"close-modal":function($event){_vm.open = false}},slot:"buttons"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }