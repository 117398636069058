<template>
    <v-btn
        class="buttons buttons--add"
        @click="edit({ id: getProfileDetails._id })"
    >
        {{ $t('global:save') }}
    </v-btn>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['profile', 'global'] },
    props: {
        action: String,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['updatePersonal']),
        async edit(payload) {
            let success = await this.updatePersonal(payload)
            if (success) this.$emit('closePersonalModal')
        },
    },
}
</script>
