<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <Header
                    :title="$t('global:yourProfile')"
                    :profile-url="'profile'"
                />

                <Modal
                    :title="$t('global:editPassword')"
                    :open="open"
                    :width="'500'"
                    :height="'520'"
                    @close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        slot="buttons"
                        :action="'create'"
                        @closeModal="open = false"
                    />
                </Modal>
                <Modal
                    :title="$t('global:editPhone')"
                    :open="openPersonal"
                    :width="'500'"
                    :height="'300'"
                    @close="closePersonalModal"
                >
                    <PersonalContent slot="AEContent" />
                    <PersonalButtons
                        slot="buttons"
                        :action="'create'"
                        @closePersonalModal="openPersonal = false"
                    />
                </Modal>
                <UserContent
                    @open-profile="openModal"
                    @open-profile-personal="openPersonalModal"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AEContent from '../../../components/Personal/Profile/Modal/Password/ProfileModalPasswordPassword'
import Buttons from './../../../components/Personal/Profile/Modal/Password//ProfileModalPasswordButtons'
import PersonalButtons from './../../../components/Personal/Profile/Modal/Personal/ProfileModalPersonalButtons.vue'
import UserContent from '../../../components/Personal/Profile/ProfileContent'
import PersonalContent from '../../../components/Personal/Profile/Modal/Personal/ProfileModalPersonalPassword.vue'
import Header from '../../../components/Personal/Profile/ProfileHeader'
import store from './../../../store/index'
import { mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['registries', 'global'] },
    components: {
        Buttons,
        PersonalButtons,
        AEContent,
        UserContent,
        PersonalContent,
        Header,
    },
    data() {
        return {
            open: false,
            openPersonal: false,
        }
    },
    computed: {},
    methods: {
        ...mapMutations(['clearProfileModal', 'clearProfileErrors']),
        closeModal() {
            this.clearProfileModal()
            this.clearProfileErrors()
            this.open = false
        },
        closePersonalModal() {
            this.clearProfileModal()
            this.clearProfileErrors()
            this.openPersonal = false
        },
        openModal() {
            this.open = true
        },
        openPersonalModal() {
            this.openPersonal = true
        },
    },
}
</script>
